import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from '@fund-base/material/material.module';
import { AppTranslationModule } from '@fund-base/translation/translation.module';
import { SizeDetectorComponent } from '@fund-base/components/size-detector/size-detector.component';
import { HttpClientModule } from '@angular/common/http';
import { AuthService } from '@fund-base/services/auth/auth.service';
import { environment } from '@fund-base/environments/environment';
import { AuthMockService } from '@fund-base/services/auth/auth.mock.service';
import { authServiceInjectionToken } from '@fund-base/constants/auth/auth.constants';
import { ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { planServiceInjectionToken } from '@fund-base/constants/plan/plan.constants';
import { PlanService } from '@fund-base/services/plan/plan.service';
import { SpacerComponent } from '@fund-base/components/spacer/spacer.component';
import { FundsMockService } from '@fund-base/services/funds/funds.mock.service';
import { FundsService } from '@fund-base/services/funds/funds.service';
import { BdcWalkModule } from 'bdc-walkthrough';

import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  DoughnutController,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PieController,
  PointElement,
  PolarAreaController,
  RadarController,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { Loader } from '@fund-base/components/loader/loader.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { fundsServiceInjectionToken } from '@fund-base/constants/funds/funds.constants';
import 'chartjs-adapter-date-fns';
import { Overlay, OverlayModule } from '@angular/cdk/overlay';
import { SelectComponent } from '@fund-base/components/select/select.component';
import { HoverClassDirective } from '@fund-base/shared/directives/hover-class/hover-class.directive';
import { PopoverDirective } from './directives/popover/popover.directive';
import { PopoverService } from '@fund-base/shared/directives/popover/popover.service';
import { MultiSelectComponent } from '@fund-base/components/multi-select/multi-select.component';

import { AddFileComponent } from '@fund-base/components/add-file/add-file.component';
import { MultiSelectAutocompleteComponent } from '@fund-base/shared/components/multi-select-autocomplete/multi-select-autocomplete.component';
import { SelectAutocompleteComponent } from '@fund-base/shared/components/select-autocomplete/select-autocomplete.component';
import { SliderRangeComponent } from '@fund-base/components/slider-range/slider-range.component';
import { DndDirective } from '@fund-base/shared/directives/drag-and-drop/drag-and-drop.directive';
import { ImageLoaderDirective } from '@fund-base/shared/directives/image-loader/image-loader.directive';
import { ExpandableComponent } from '@fund-base/components/expandable/expandable.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SafePipe } from '@fund-base/shared/pipes/safe.pipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ConfirmDialogComponent } from '@fund-base/components/confirm-dialog/confirm-dialog.component';
import { NgChartsModule } from 'ng2-charts';
import { GoogleChartsModule } from 'angular-google-charts';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MobileNavbarToggleComponent } from '@fund-base/components/mobile-navbar-toggle/mobile-navbar-toggle.component';
import { SvgIconComponent } from './components/svg-icon/svg-icon.component';
import { FundTypeClassPipe } from './pipes/fund-type-class.pipe';
import { HebrewTextPipe } from './pipes/hebrew-text.pipe';
import { FeatureFlagDirective } from './directives/feature-flags/feature-flag.directive';
import { FalseFeatureFlagDirective } from './directives/feature-flags/false-feature-flag.directive';

@NgModule({
  declarations: [
    SizeDetectorComponent,
    SpacerComponent,
    Loader,
    ConfirmDialogComponent,
    MobileNavbarToggleComponent,
    SelectComponent,
    SelectAutocompleteComponent,
    MultiSelectComponent,
    MultiSelectAutocompleteComponent,
    SliderRangeComponent,
    PopoverDirective,
    HoverClassDirective,
    AddFileComponent,
    DndDirective,
    ImageLoaderDirective,
    ExpandableComponent,
    SafePipe,
    SvgIconComponent,
    FundTypeClassPipe,
    HebrewTextPipe,
    FeatureFlagDirective,
    FalseFeatureFlagDirective,
  ],
  imports: [
    // modules
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    AppMaterialModule,
    AppTranslationModule,
    NgChartsModule,
    NgxSliderModule,
    SlickCarouselModule,
    OverlayModule,
    NgxDatatableModule,
    InfiniteScrollModule,
    GoogleChartsModule,
    MatProgressBarModule,
    BdcWalkModule,
  ],
  exports: [
    // modules
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    ToastrModule,
    AppMaterialModule,
    AppTranslationModule,
    NgChartsModule,
    NgxSliderModule,
    SlickCarouselModule,
    NgxDatatableModule,
    InfiniteScrollModule,
    GoogleChartsModule,
    BdcWalkModule,
    // components
    SizeDetectorComponent,
    SpacerComponent,
    Loader,
    SelectComponent,
    SelectAutocompleteComponent,
    MultiSelectComponent,
    MultiSelectAutocompleteComponent,
    MobileNavbarToggleComponent,
    SliderRangeComponent,
    ExpandableComponent,
    AddFileComponent,
    SvgIconComponent,

    // directives
    PopoverDirective,
    HoverClassDirective,
    DndDirective,
    ImageLoaderDirective,
    FeatureFlagDirective,
    FalseFeatureFlagDirective,
    // pipes
    SafePipe,
    FundTypeClassPipe,
    HebrewTextPipe,
  ],
  providers: [
    { provide: authServiceInjectionToken, useClass: environment.mock ? AuthMockService : AuthService },
    { provide: planServiceInjectionToken, useClass: PlanService },
    { provide: fundsServiceInjectionToken, useClass: environment.mock ? FundsMockService : FundsService },
    Overlay,
    PopoverService,
  ],
})
export class AppSharedModule {
  constructor() {
    // install Chart modules
    Chart.register(
      ChartDataLabels,
      ArcElement,
      BarController,
      BarElement,
      CategoryScale,
      DoughnutController,
      LinearScale,
      LineController,
      LineElement,
      PieController,
      PointElement,
      PolarAreaController,
      RadarController,
      RadialLinearScale,
      Title,
      Tooltip,
      Legend,
      TimeScale,
      TimeSeriesScale
    );
  }
}
