import { Action, createReducer, on } from '@ngrx/store';
import {
  createSavedSearchSuccess,
  deleteSavedSearchSuccess,
  fetchSavedSearchesSuccess,
} from '../actions/saved-searches.actions';
import { initialSavedSearchesState, SavedSearchesState } from '../state/saved-searches.state';

export const featureSavedSearchesKey = 'saved-searches';

const _savedSearchesReducer = createReducer<SavedSearchesState, Action>(
  initialSavedSearchesState,
  on(fetchSavedSearchesSuccess, (state, { savedSearches }) => ({ ...state, savedSearches })),
  on(createSavedSearchSuccess, (state, { savedSearch }) => ({
    ...state,
    savedSearches: [...state.savedSearches, savedSearch],
  })),
  on(deleteSavedSearchSuccess, (state, { savedSearch }) => {
    const updatedSearches = state.savedSearches.filter(currSavedSearch => currSavedSearch.id !== savedSearch.id);
    return {
      ...state,
      savedSearches: updatedSearches,
    };
  })
);

export function savedSearchesReducer(state: SavedSearchesState | undefined, action: Action) {
  return _savedSearchesReducer(state, action);
}
