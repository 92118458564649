import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseSavedSearchesRoute } from '@fund-base/constants/saved-searches/saved-searches.constants';
import {
  CreateSavedSearchReqBody,
  SavedSearchesEntity,
} from '@fund-base/types/saved-searches/constants/saved-searches.types';
import { catchError, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SavedSearchesService {
  constructor(private httpClient: HttpClient) {}

  getSavedSearchesList(language: string) {
    const url = new URL(BaseSavedSearchesRoute);
    url.searchParams.append('lang', language);
    return this.httpClient.get<SavedSearchesEntity[]>(url.toString()).pipe(
      // Catch and format errors
      catchError(error => {
        // Log or transform the error message
        const formattedError = this.getErrorMessage(error);
        console.error(formattedError);
        // Throw a new formatted error
        return throwError(() => new Error(formattedError));
      })
    );
  }

  createSavedSearch(savedSearch: CreateSavedSearchReqBody) {
    return this.httpClient.post<SavedSearchesEntity>(BaseSavedSearchesRoute, savedSearch).pipe(
      // Catch and format errors
      catchError(error => {
        // Log or transform the error message
        const formattedError = this.getErrorMessage(error);
        console.error(formattedError);
        // Throw a new formatted error
        return throwError(() => new Error(this.getErrorMessage(error)));
      })
    );
  }

  deleteSavedSearch(savedSearch: SavedSearchesEntity) {
    return this.httpClient.delete<SavedSearchesEntity>(BaseSavedSearchesRoute + `/${savedSearch.id}`).pipe(
      // Catch and format errors
      catchError(error => {
        // Log or transform the error message
        const formattedError = this.getErrorMessage(error);
        console.error(formattedError);
        // Throw a new formatted error
        return throwError(() => new Error(formattedError));
      })
    );
  }

  private getErrorMessage(error: HttpErrorResponse): string {
    console.error(`SavedSearchesService error | status: ${error.status}`, error.error);
    if (error.status === 404) {
      return error.error.message || 'Saved searches not found.';
    } else if (error.status === 500) {
      return error.error.message || 'Server error occurred. Please try again later.';
    } else {
      return error.error.message || 'An unexpected error occurred. Please try again.';
    }
  }
}
