import { Injectable } from '@angular/core';
import { SavedSearchesService } from '@fund-base/services/saved-searches/saved-searches.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of } from 'rxjs';
import {
  createSavedSearches,
  createSavedSearchFailure,
  createSavedSearchSuccess,
  deleteSavedSearch,
  deleteSavedSearchFailure,
  deleteSavedSearchSuccess,
  fetchSavedSearches,
  fetchSavedSearchesFailure,
  fetchSavedSearchesSuccess,
} from '../actions/saved-searches.actions';
import { SavedSearchesEntity } from '@fund-base/types/saved-searches/constants/saved-searches.types';

@Injectable()
export class SavedSearchesEffects {
  constructor(private actions$: Actions, private _savedSearchesService: SavedSearchesService) {}

  fetchSavedSearches$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchSavedSearches),
      mergeMap(({ language, onSuccess, onError }) => {
        return this._savedSearchesService.getSavedSearchesList(language).pipe(
          map(response => {
            if (!!onSuccess) onSuccess(response);
            return fetchSavedSearchesSuccess({
              savedSearches: response,
            });
          }),
          catchError(error => {
            if (!!onError) onError(error);
            return of(fetchSavedSearchesFailure({ error: error.message }));
          })
        );
      })
    )
  );

  createSavedSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createSavedSearches),
      mergeMap(({ createSavedSearchReqBody, onSuccess, onError }) => {
        return this._savedSearchesService.createSavedSearch(createSavedSearchReqBody).pipe(
          map(response => {
            if (!!onSuccess) onSuccess(response);
            return createSavedSearchSuccess({
              savedSearch: response,
            });
          }),
          catchError(error => {
            if (!!onError) onError(error);
            return of(createSavedSearchFailure({ error: error.message }));
          })
        );
      })
    )
  );

  deleteSavedSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteSavedSearch),
      mergeMap(({ savedSearch, onSuccess, onError }) => {
        return this._savedSearchesService.deleteSavedSearch(savedSearch).pipe(
          map(response => {
            if (!!onSuccess) onSuccess(response);
            return deleteSavedSearchSuccess({
              savedSearch: response,
            });
          }),
          catchError(error => {
            if (!!onError) onError(error);
            return of(deleteSavedSearchFailure({ error: error.message }));
          })
        );
      })
    )
  );
}
