import { SuccessCallback } from '@fund-base/types/general/functions';
import {
  CreateSavedSearchReqBody,
  SavedSearchesEntity,
} from '@fund-base/types/saved-searches/constants/saved-searches.types';
import { createAction, props } from '@ngrx/store';

export const fetchSavedSearches = createAction(
  '[Saved Searches] Fetch saved searches',
  props<{ language: string; onSuccess?: SuccessCallback<SavedSearchesEntity[]>; onError?: ErrorCallback }>()
);

export const fetchSavedSearchesSuccess = createAction(
  '[Saved Searches] Fetch saved searches success',
  props<{ savedSearches: SavedSearchesEntity[]; onSuccess?: SuccessCallback<SavedSearchesEntity[]> }>()
);

export const fetchSavedSearchesFailure = createAction(
  '[Saved Searches] Fetch saved searches failure',
  props<{ error: string; onError?: ErrorCallback }>()
);

export const createSavedSearches = createAction(
  '[Saved Searches] Create saved searches',
  props<{
    createSavedSearchReqBody: CreateSavedSearchReqBody;
    onSuccess?: SuccessCallback<SavedSearchesEntity>;
    onError?: ErrorCallback;
  }>()
);

export const createSavedSearchSuccess = createAction(
  '[Saved Searches] Create saved search success',
  props<{ savedSearch: SavedSearchesEntity; onSuccess?: SuccessCallback<SavedSearchesEntity> }>()
);

export const createSavedSearchFailure = createAction(
  '[Saved Searches] Create saved search failure',
  props<{ error: string; onError?: ErrorCallback }>()
);

export const deleteSavedSearch = createAction(
  '[Saved Searches] Delete saved search',
  props<{
    savedSearch: SavedSearchesEntity;
    onSuccess?: SuccessCallback<SavedSearchesEntity>;
    onError?: ErrorCallback;
  }>()
);

export const deleteSavedSearchSuccess = createAction(
  '[Saved Searches] Delete saved search success',
  props<{ savedSearch: SavedSearchesEntity; onSuccess?: SuccessCallback<SavedSearchesEntity> }>()
);

export const deleteSavedSearchFailure = createAction(
  '[Saved Searches] Delete saved search failure',
  props<{ error: string; onError?: ErrorCallback }>()
);
